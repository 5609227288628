var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h1',{staticClass:"mainTitle"},[_vm._v("影像云存储")]),_c('h2',{staticClass:"subtitle"},[_vm._v("Medical image cloud storage")]),_c('div',{staticClass:"dataBackup"},[_vm._m(0),_c('div',{staticClass:"dataBackupItem"},[_c('div',{staticClass:"dataBackipItemImg"},[_c('img',{attrs:{"src":require('@/assets/images/web/product/yixiangshuju.png'),"alt":""}})]),_vm._m(1)]),_c('div',{staticClass:"dataBackupItem"},[_c('div',{staticClass:"dataBackipItemImg"},[_c('img',{attrs:{"src":require('@/assets/images/web/product/shujubeifen1.png'),"alt":""}})]),_vm._m(2)]),_c('div',{staticClass:"dataBackupItem"},[_c('div',{staticClass:"dataBackipItemImg"},[_c('img',{attrs:{"src":require('@/assets/images/web/product/pacs.png'),"alt":""}})]),_vm._m(3)])]),_c('div',{staticClass:"container deploymentSchemeContainer"},[_c('div',{staticClass:"deploymentScheme"},[_vm._m(4),_c('div',{staticClass:"deploymentSchemeImg"},[_c('img',{attrs:{"src":require('@/assets/images/web/product/bishufangan.png'),"alt":""}})])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dataBackupInfo"},[_c('div',{staticClass:"dataBackupTitle"},[_vm._v("数据备份")]),_c('div',{staticClass:"dataBackupSubtitle"},[_vm._v(" 影像云端存储帮助医院解决生产运营系统和数据的安全。 ")]),_c('p',{staticClass:"dataBackupDesc"},[_vm._v(" 影像存储系统将医学影像数据从现有的医院数据中心通过网络转存至云平台，实现PACS应用备份、影像数据容灾备份等功能。满足在自然灾害、硬盘故障、黑客攻击、人为损坏等情况下，医疗机构能够持续稳定的对外提供服务。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dataBackupItemInfo"},[_c('div',{staticClass:"dataBackupItemTitle"},[_vm._v("影像云归档")]),_c('p',{staticClass:"dataBackupItemDesc"},[_vm._v(" 用于已部署PACS的医院，医院PACS存储空间不足，不能满足医院影像数据高速增长的需求，建议本地存储高频访问的数据，云端存储历史归档数据。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dataBackupItemInfo"},[_c('div',{staticClass:"dataBackupItemTitle"},[_vm._v("影像数据异地容灾备份")]),_c('p',{staticClass:"dataBackupItemDesc"},[_vm._v(" 用于已部署PACS的医院，不影响现有系统，数据云端备份，本地数据损坏后按需恢复。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dataBackupItemInfo"},[_c('div',{staticClass:"dataBackupItemTitle"},[_vm._v("PACS应用及备份")]),_c('p',{staticClass:"dataBackupItemDesc"},[_vm._v(" 医院有完备的信息系统，为防止业务中断，部署灾备PACS，在主用PACS系统故障后，灾备PACS系统可以应用级容灾接管，提高了医院在PACS系统出现故障后的业务持续能力。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deploymentSchemeInfo"},[_c('div',{staticClass:"deploymentSchemeTitle"},[_vm._v("部署方案")]),_c('p',{staticClass:"deploymentSchemeDesc"},[_vm._v(" 指云端数字云影像系统连接医院PACS系统，架设安全设备防火墙，通过前置机对影像数据进行采集和治理，经过无损压缩后上传至影像云平台，供医生和患者随时调阅。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container partnerContainer"},[_c('div',{staticClass:"partnerTitle"},[_vm._v("合作商家")]),_c('div',{staticClass:"partnerSubtitle"},[_vm._v("COOPERATIVE MERCHANT")]),_c('div',{staticClass:"cec"},[_c('div',{staticClass:"cecImg"}),_c('div',{staticClass:"cecInfo"},[_c('div',{staticClass:"cecTitle"},[_vm._v("中电数据服务有限公司")]),_c('div',{staticClass:"cecDesc"},[_vm._v("该企业是中央直接管理的国有重要骨干企业。国务院国资委认定的唯一一家以网络安全和信息化为核心主业的中央企业。中电数据服务有限公司（中电数据），是专注于健康医疗大数据整合、管理和应用服务的平台公司。打造数字健康智能化健康医疗服务，通过多种方式汇聚诊疗数据、行为数据和环境数据等个人相关的健康医疗大数据，构建以个人为中心的全生命周期“数字健康档案”。")])])])])
}]

export { render, staticRenderFns }