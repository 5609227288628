<template>
  <div class="content">
    <h1 class="mainTitle">影像云存储</h1>
    <h2 class="subtitle">Medical image cloud storage</h2>
    <div class="dataBackup">
      <div class="dataBackupInfo">
        <div class="dataBackupTitle">数据备份</div>
        <div class="dataBackupSubtitle">
          影像云端存储帮助医院解决生产运营系统和数据的安全。
        </div>
        <p class="dataBackupDesc">
          影像存储系统将医学影像数据从现有的医院数据中心通过网络转存至云平台，实现PACS应用备份、影像数据容灾备份等功能。满足在自然灾害、硬盘故障、黑客攻击、人为损坏等情况下，医疗机构能够持续稳定的对外提供服务。
        </p>
      </div>
      <div class="dataBackupItem">
        <div class="dataBackipItemImg">
          <img
            :src="require('@/assets/images/web/product/yixiangshuju.png')"
            alt=""
          />
        </div>
        <div class="dataBackupItemInfo">
          <div class="dataBackupItemTitle">影像云归档</div>
          <p class="dataBackupItemDesc">
            用于已部署PACS的医院，医院PACS存储空间不足，不能满足医院影像数据高速增长的需求，建议本地存储高频访问的数据，云端存储历史归档数据。
          </p>
        </div>
      </div>
      <div class="dataBackupItem">
        <div class="dataBackipItemImg">
          <img
            :src="require('@/assets/images/web/product/shujubeifen1.png')"
            alt=""
          />
        </div>
        <div class="dataBackupItemInfo">
          <div class="dataBackupItemTitle">影像数据异地容灾备份</div>
          <p class="dataBackupItemDesc">
            用于已部署PACS的医院，不影响现有系统，数据云端备份，本地数据损坏后按需恢复。
          </p>
        </div>
      </div>
      <div class="dataBackupItem">
        <div class="dataBackipItemImg">
          <img :src="require('@/assets/images/web/product/pacs.png')" alt="" />
        </div>
        <div class="dataBackupItemInfo">
          <div class="dataBackupItemTitle">PACS应用及备份</div>
          <p class="dataBackupItemDesc">
            医院有完备的信息系统，为防止业务中断，部署灾备PACS，在主用PACS系统故障后，灾备PACS系统可以应用级容灾接管，提高了医院在PACS系统出现故障后的业务持续能力。
          </p>
        </div>
      </div>
    </div>
    <div class="container deploymentSchemeContainer">
      <div class="deploymentScheme">
        <div class="deploymentSchemeInfo">
          <div class="deploymentSchemeTitle">部署方案</div>
          <p class="deploymentSchemeDesc">
            指云端数字云影像系统连接医院PACS系统，架设安全设备防火墙，通过前置机对影像数据进行采集和治理，经过无损压缩后上传至影像云平台，供医生和患者随时调阅。
          </p>
        </div>
        <div class="deploymentSchemeImg">
          <img
            :src="require('@/assets/images/web/product/bishufangan.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- <div class="cec">
      <div class="cecTitle">中电数据合作项目</div>
      <div class="cecSubtitle">共建成都市健康医疗大数据平台</div>
      <div class="cecImg"></div>
    </div> -->

    <div class="container partnerContainer">
      <div class="partnerTitle">合作商家</div>
      <div class="partnerSubtitle">COOPERATIVE MERCHANT</div>
      <div class="cec">
        <div class="cecImg"></div>
        <div class="cecInfo">
          <div class="cecTitle">中电数据服务有限公司</div>
          <div class="cecDesc">该企业是中央直接管理的国有重要骨干企业。国务院国资委认定的唯一一家以网络安全和信息化为核心主业的中央企业。中电数据服务有限公司（中电数据），是专注于健康医疗大数据整合、管理和应用服务的平台公司。打造数字健康智能化健康医疗服务，通过多种方式汇聚诊疗数据、行为数据和环境数据等个人相关的健康医疗大数据，构建以个人为中心的全生命周期“数字健康档案”。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curseId: 'partner0',
      partner: [
        {
          id: 'partner0',
          imgPath: require('@/assets/images/web/product/zhongdianshuju.png'),
          title: '中电数据项目',
          info: '中电数据项目与全国各省市共享健康医疗大数据平台，旨在云计算、大数据、物联网、移动医疗等先进信息技术与健康服务深度融合，有效解决数据共享难题，打破信息孤岛，助力医疗机构提升服务水平，支撑各省市健康医疗行业高速发展。'
        },
        {
          id: 'partner1',
          imgPath: require('@/assets/images/web/product/kaente.png'),
          title: '成都卡恩特医疗科技有限公司',
          info: '成都卡恩特医疗科技有限公司致力于智慧医疗产品研发。其核心产品指云端是基于健康医疗大数据的智慧医疗服务平台。通过共享健康医疗信息，为用户提供移动远程会诊、在线诊疗、医疗数据查阅等个性化服务。'
        },
        {
          id: 'partner2',
          imgPath: require('@/assets/images/web/product/cec.png'),
          title: '中电数据服务有限公司',
          info: '该企业是中央直接管理的国有重要骨干企业。国务院国资委认定的唯一一家以网络安全和信息化为核心主业的中央企业。中电数据服务有限公司（中电数据），是专注于健康医疗大数据整合、管理和应用服务的平台公司。打造数字健康智能化健康医疗服务，通过多种方式汇聚诊疗数据、行为数据和环境数据等个人相关的健康医疗大数据，构建以个人为中心的全生命周期“数字健康档案”。'
        }
      ]
    }
  },
  mounted: function () {
    this.setTransform()
  },
  methods: {
    partnerChange(now, last) {
      console.log(now, '当前ID')
      console.log(last, '上一个ID')
      if (now === 0) {
        this.curseId = 'partner0'
      } else if (now === 1) {
        this.curseId = 'partner1'
      } else {
        this.curseId = 'partner2'
      }
      this.setTransform()
    },
    setTransform() {
      if (this.curseId === 'partner0') {
        // 当前展示的是第0个，那么左边的轮播是第2个，右边是第1个
        console.log(this.$refs.partner0, '第0个轮播')
        console.log(this.$refs.partner1, '第1个轮播')
        console.log(this.$refs.partner2, '第2个轮播')
        // this.$refs.partner0[0].$el.style.left = '0px'
        // this.$refs.partner1[0].$el.style.left = '300px'
        // this.$refs.partner2[0].$el.style.left = '-300px'
      } else if (this.curseId === 'partner1') {
        // 当前展示的是第1个，那么左边的轮播是第0个，右边是第2个
        console.log(this.$refs.partner0, '第0个轮播')
        console.log(this.$refs.partner1, '第1个轮播')
        console.log(this.$refs.partner2, '第2个轮播')
        // this.$refs.partner0[0].$el.style.left = '-300px'
        // this.$refs.partner1[0].$el.style.left = '0px'
        // this.$refs.partner2[0].$el.style.left = '300px'
      } else {
        // 当前展示的是第2个，那么左边的轮播是第1个，右边是第0个
        console.log(this.$refs.partner0, '第0个轮播')
        console.log(this.$refs.partner1, '第1个轮播')
        console.log(this.$refs.partner2, '第2个轮播')
        // this.$refs.partner0[0].$el.style.left = '300px'
        // this.$refs.partner1[0].$el.style.left = '-300px'
        // this.$refs.partner2[0].$el.style.left = '0px'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  .container {
    width: 100%;
  }
  .mainTitle {
    text-align: center;
    font-family: PingFang SC;
    margin: 0 auto;
    margin-top: 100px;
    color: #29262a;
    font-weight: bold;
    font-size: 45px;
  }
  .subtitle {
    text-align: center;
    font-size: 18px;
    font-family: AngsanaUPC;
    font-weight: 400;
    color: #afb4bc;
    margin: 0 auto;
    margin-top: 33px;
    letter-spacing: 6px;
  }
  .dataBackup {
    overflow: hidden;
    width: 1200px;
    height: 1250px;
    margin: 0 auto;
    margin-top: 64px;
    box-shadow: 0px 4px 29px 0px rgba(12, 41, 72, 0.14);
    .dataBackupInfo {
      overflow: hidden;
      width: 100%;
      height: 388px;
      background: url('../../../../assets/images/web/product/shujubeifen.png')
        no-repeat;
      background-size: 100% 100%;
      .dataBackupTitle {
        text-align: center;
        font-size: 45px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        margin-top: 85px;
      }
      .dataBackupSubtitle {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-top: 31px;
      }
      .dataBackupDesc {
        display: block;
        width: 1062px;
        margin: 0 auto;
        text-align: center;
        margin-top: 38px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #bfbebe;
        line-height: 41px;
      }
    }
    .dataBackupItem {
      &:last-child {
        border-bottom: none;
      }
      border-bottom: 1px solid rgba(12, 42, 72, 0.04);
      width: 1120px;
      height: 280px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      .dataBackipItemImg {
        img{
          width: 100%;
          height: 100%;
          display: block;
        }
        width: 251px;
        height: 190px;
        background: darkgray;
      }
      .dataBackupItemInfo {
        flex: 1;
        margin-left: 40px;
        .dataBackupItemTitle {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #343840;
        }
        .dataBackupItemDesc {
          margin-top: 32px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #524c52;
          line-height: 33px;
          letter-spacing: 1.5px;
        }
      }
    }
  }
  .deploymentSchemeContainer {
    background: #f9f9f9;
    height: 651px;
    margin-top: 57px;
    overflow: hidden;
    .deploymentScheme {
      margin: 0 auto;
      width: 1200px;
      height: 542px;
      display: flex;
      flex-direction: row;
      margin-top: 43px;
      .deploymentSchemeInfo {
        width: 480px;
        height: 542px;
        display: flex;
        flex-direction: column;
        .deploymentSchemeTitle {
          margin-top: 69px;
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #29262a;
          margin: 0 !important;
          margin-top: 168px !important;
        }
        .deploymentSchemeDesc {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #646a73;
          line-height: 47px;
          margin-top: 46px;
          text-align: justify;
        }
      }
      .deploymentSchemeImg {
        margin-left: 131px;
        width: 626px;
        height: 542px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // .cec {
  //   border: 1px solid transparent;
  //   width: 1200px;
  //   height: 913px;
  //   margin: 0 auto;
  //   .cecTitle {
  //     text-align: center;
  //     margin-top: 118px;
  //     font-size: 52px;
  //     font-family: PingFang SC;
  //     font-weight: bold;
  //     color: #29262a;
  //   }
  //   .cecSubtitle {
  //     font-size: 26px;
  //     font-family: Microsoft YaHei;
  //     font-weight: 400;
  //     color: #646a73;
  //     margin-top: 36px;
  //     text-align: center;
  //     letter-spacing: 3px;
  //   }
  //   .cecImg {
  //     width: 1200px;
  //     height: 550px;
  //     margin-top: 77px;
  //     background: url('../../../../assets/images/web/product/zhongdian.png');
  //     background-size: 100% 100%;
  //   }
  // }
  .partnerContainer {
    background: white;
    height: 571px;
    display: inline-block;
    .partnerTitle {
      font-size: 45px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #000000;
      text-align: center;
      margin-top: 54px;
    }
    .partnerSubtitle {
      text-align: center;
      font-size: 22px;
      font-family: AngsanaUPC;
      font-weight: 400;
      color: rgba(0,0,0, 0.55);
      margin-top: 27px;
    }
    .cec{
      width: 1200px;
      height: 309px;
      border: 1px solid #D9D9D9;
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      .cecImg{
        margin-top: 32px;
        width: 334px;
        height: 242px;
        margin-left: 32px;
        background: url('../../../../assets/images/web/product/cec.png') no-repeat;
        background-size: 100% 100%;
      }
      .cecInfo{
        width: 739px;
        height: 242px;
        margin-left: 50px;
        .cecTitle{
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #29262A;
          line-height: 31px;
          margin-top: 53px;
        }
          .cecDesc{
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #524C52;
            line-height: 35px;
            margin-top: 32px;
          }
        
      }
    }
  }
}
</style>
